import React from "react"
import { Script } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header"

import "../styles/upload.css"

const Upload = () => {
  return (
    <>
      <Header />
      <Layout>
        <div className="upload_embed">
          <div className="upload_embed_container">
            <div id="MA _lead_form"></div>
            <Script src="https://www.mortgageautomator.com/maloader.js" />

            <Script
              dangerouslySetInnerHTML={{
                __html: `
        setTimeout(()=>{
          MortgageAutomatorLoader.register(function () {
            ["https://www.mortgageautomator.com/hosted/style_lead_sample.css"].forEach(
              function (src) {
                var_css = document.createElement("link");
                _css.rel = "stylesheet";
                _css.type = "text/css";
                _css.href = src + "?v=" + Date.now();
                document.head.appendChild(_css);
              }
            );
            MortgageAutomator.lead.successTitle = "";
            MortgageAutomator.lead.successMessage = "Thank you.";
            MortgageAutomator.lead.redirectURL = "javascript:window.location.reload();";
            var gsid = 10176;
            var token = "8c61bedddc15a531c6c9fa298a3452e0f2d0ad22";
            MortgageAutomator.lead.load("MA_lead_form", gsid, token);
          });
        },[3000])
        `,
              }}
            />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Upload
